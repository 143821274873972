import { createContext } from 'react'
import { AuthState, initialAuthState } from './state'

export interface AuthContextInterface extends AuthState {
	login: (email: string, password: string) => Promise<void>
	logout: () => Promise<void>
	fetchAdmin: () => Promise<void>
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <AuthProvider>.')
}

const initialContext = {
	...initialAuthState,
	login: stub,
	logout: stub,
	fetchAdmin: stub,
}

const AuthContext = createContext<AuthContextInterface>(initialContext)

export default AuthContext
